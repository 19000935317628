.reader-treatment {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 10px;
  max-width: 800px;
  width: inherit;
  overflow: hidden;
  margin-bottom: 70px;

  &--nolink {
    .reader-treatment__more-button {
      display: none;
    }
  }

  &__wrapper {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 680px) {
      //padding: 0;
    }
  }

  &__image-wrapper {
    width: 50%;
  }

  &__image {
    width: auto;
    height: 350px;
    align-self: center;
  }

  &__info-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    ul {
      padding-inline-start: 20px;
      padding-left: 20px;
      list-style-type: disc;
      font-weight: 300;
      margin: 0;
    }
  }

  &__full-text {
    padding: 40px;
    text-decoration: none;
    column-count: 2;
    @media only screen and (max-width: 680px) {
      column-count: 1;
    }

    p {
      margin: 20px 0;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 10px;
  }

  &__more-button {
    background-color: $lightblue;
    text-decoration: none;
    color: #0089ff;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    transition: 0.5s;
    border-radius: 0 0 10px 10px;

    &:after {
      content: '➔';
    }

    &:hover {
      background-color: $blue;
      color: white;
    }
  }
}
@media only screen and (max-width: 1380px) {
  .reader-treatment {
    max-width: 700px;

    &__image {
      height: 275px;
    }

    &__full-text p,
    ul {
      font-size: 18px;
    }

    &__more-button {
      font-size: 18px;
    }
  }
}
@media only screen and (max-width: 720px) {
  .treatment-page {
    .reader-content-wrapper {
      padding-top: 70px;

      .reader-treatment {
        margin: 0;
      }
    }

    .reader-treatment {
      max-width: none;
      width: auto;

      &__wrapper {
        flex-direction: column;
        padding: 40px 40px 0;
      }

      &__image-wrapper {
        width: auto;
      }

      &__info-wrapper {
        width: auto;
        padding: 40px 0;
        margin-left: 0;
      }
    }

    .reader-treatment__image {
      width: 80%;
      height: auto;

      &-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .treatment-page {
    .reader-content-wrapper {
      padding-top: 70px;
      padding-bottom: 0;

      .reader-treatment {
        margin: 0;
      }
    }
  }
}
