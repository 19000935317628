.reader-keypoints {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $blue;
  margin: 0;
  padding: 50px 0;

  h3 {
    color: white;
  }

  ul {
    padding: 0;
    padding: 0;
    list-style-type: none;
    width: 600px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }

    li {
      padding: 20px 30px;
      font-size: 20px;
      font-weight: 500;
      background-color: white;
      margin: 9px 0;
      border-radius: 5px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3411764705882353);
      display: flex;
      align-items: center;
      @media screen and (max-width: 700px) {
        margin: 9px 40px;
      }
    }
  }
  @media only screen and (max-width: 1380px) {
    h3 {
      font-size: 35px;
    }

    ul {
      li {
        font-size: 18px;
      }
    }
  }
}
