$green : #48dc7a;
$green-exam: #00d448;
$blue: #0089ff;
$red: #ed0048;
$lightred: #ff5d5d;
$lightblue : #dbf0ff;
$navy: #2d75cf;
$lightgrey : #dcdcdc;
$light-grey-select : #f7f7f7;

@media only print {
    body { display:none !important; }
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  color: #717171;
  font: 20px/1.2 "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  user-select: none;
}
a {
  cursor: pointer;
  color:inherit;
}
img {
  pointer-events: none;
}
.flex-row{
  display: flex;
  justify-content: center;
}

.hidden{
  display: none !important;
}

.flex-column{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 140px 0;
}

.round-button {
  padding: 20px 50px;
  background-color: #e3e3e3;
  text-align: center;
  font-weight: 500;
  border-radius: 40px;
  color: #ffffff;
  align-self: center;
  transition: 0.5s;
  display: inline-block;
  opacity: 1;

  &:hover {
    background-color: $blue;
  }

  &--cancel {
    &:hover {
      background-color: red;
    }
  }

  &--gray {
    background: none;
    border: 2px solid #cbcbcb;
    color: #cbcbcb;

    &:hover {
      background-color: #cbcbcb;
      color: white;
    }
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.disable-pointer-events{
  pointer-events: none;
}
