.business-main {
  display: flex;
  justify-content: center;

  .menu-message__wrapper {
    border-radius: 10px;
    padding: 15px 30px;
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .cource-circles {
    max-width: 5vw;
  }

  .course-circle {
    height: 25px;
    border-radius: 5px;
    padding: 0 5px;
    color: #b5b5b5;
    background-color: #ebebeb;
    display: inline-flex;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    margin: 2px;
    position: relative;

    &__tooltip {
      position: absolute;
      background-color: #484848;
      padding: 5px;
      bottom: 100%;
      margin-bottom: 5px;
      border-radius: 5px;
      color: white;
      visibility: hidden;
      white-space: nowrap;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #484848 transparent transparent transparent;
      }
    }

    &:hover {
      .course-circle__tooltip {
        visibility: visible;
      }
    }

    &--expired {
      background-color: $red;
      border: 1px solid $red;
      color: white;
    }
    &--complete {
      background-color: $green;
      border: 1px solid $green;
      color: white;
    }
  }
}

.business-wrapper {
  padding: 140px 20px;
  width: 100%;
}

.business-top-message {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 45px;
    margin: 0;
  }
}

.top-message-foldable {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
  display: none;

  li {
    padding: 5px 0;
    font-size: 18px;
  }
}

a.top-message-info-button {
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  font-weight: 500;

  &:hover {
    background-color: $light-grey-select;
  }
}

.business-course-credits-wrapper {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 40px;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 5px;

    &:first-child {
      text-align: center;
    }

    &:last-child {
      text-align: right;
      padding-right: 20px;
    }
  }

  td {
    &:first-child {
      text-align: center;
    }

    &:last-child {
      text-align: right;
      padding-right: 30px;
    }
  }

  thead {
    text-align: left;
  }

  thead,
  tr {
    border-bottom: 1px solid #eeeeee;

    &:last-child {
      border-bottom: none;
    }
  }
}

.business-course-title {
  padding: 30px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.business-course-credit__value {
  font-weight: 700;
  font-size: 20px;
  padding: 25px;
  width: 40px;
  text-align: center;
}

.business-course-credit__name {
  font-size: 18px;
  margin-left: 40px;
}

.business-course-add-button {
  background-color: #0089ff;
  color: white;
  border-radius: 10px;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 40px;
  transition: 0.5s;

  &:hover {
    background-color: $navy;
  }

  &__icon {
    background-image: url("/assets/images/adduser.svg");
    width: 35px;
    height: 35px;
    background-size: cover;
  }
}

.business-course-users {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;

  .business-course-user-table {
    width: 100%;
    border-spacing: 0;
  }

  th {
    padding: 20px 0;
    background-color: #e6f3ff;
    text-align: left;

    &:first-child {
      padding-left: 30px;
    }
  }

  tr:hover {
    background-color: $light-grey-select;
  }

  td {
    padding: 20px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 16px;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding: 0;
    }

    a {
      padding: 10px;
    }
  }
}

.business-course-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.business-control-filters {
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: bold;
  }

  select {
    border: none;
    font-size: 14px;
    height: 30px;
    background-color: #f6f6f6;
    color: #898989;
    margin-top: 5px;
    padding: 0;
    margin-left: 15px;
  }
}

.business-control-pages {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: inline-flex;
  overflow: hidden;

  &__button {
    font-size: 16px;
    width: 50px;
    height: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    &--active {
      background-color: $lightblue;
    }

    &:hover {
      background-color: $light-grey-select;
    }
  }
}
