h1,
h2,
h3,
p {
  margin: 0;
}

h1,h2,h3{
  line-height: 1;
}

p {
  font-size: 19px;
}

ul {
  list-style-type: square;
}

h1 {
  font-size: 68px;
  line-height: 55px;
  color: #717171;
  margin-bottom: 23px;
  font-weight: 700;
}

h2 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #717171;
    line-height: 35px;
}

h3 {
  color: #e1e1e1;
  font-size: 44px;
}

h4 {
  margin: 10px;
  font-size: 39px;
  font-weight: 300;
  margin-bottom: 50px;
}
