.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    width: 400px;
    margin: 30px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:20px;

    &__logo {
      width: 280px;
      height: 65px;
      background-image: url("/assets/images/logo_login.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 10px;
    }
    h2{
      font-size: 35px;
    }
  }

  &-window {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &-bottom-link{
    text-align: center;
    font-size: 17px;
    padding:20px;
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    &--grey{
      color: #717171;
    }
    &--error{
      color:red;
    }
    &--success{
      color:$green-exam;
    }
  }
  &-reset-text{
    text-align: center;
    font-size: 18px;
    margin-bottom:10px;
  }


  &-form {
    width: 100%;
    margin: 0;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      color: #898989;
      padding: 0;
      text-indent: 20px;
      background-color: white;
      border-radius: 10px;
      font-size: 18px;
      line-height: 28px;
      border: 1px solid #e5e5e5;
      -webkit-appearance: none;
      height: 50px;
      margin-bottom: 16px;
      outline: none;
      width: 100%;
    }

    input[type="submit"] {
      width: 100%;
      height: 50px;
      font-size: 18px;
      border: none;
      background-color: $green;
      color: white;
      text-align: left;
      transition: 0.5s;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      border-radius: 10px;

      &:hover {
        background-color: $blue;
      }
    }
  }
}
