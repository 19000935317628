.info-message-link {
  color: #2b96f1;
  font-weight: 500;
}
.info-message {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #2b96f1;
  z-index: 2000;
  display: none;
}
.info-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
}
.info-message__text {
  color: #fff;
  line-height: 1;
  font-size: 17px;
  font-weight: 500;
  padding-right: 30px;
}
.info-message-wrapper:after {
  content: "";
  min-height: 50px;
  min-width: 50px;
  background-image: url('/assets/images/info.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
}
