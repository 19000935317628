.modal-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.85);
  z-index: 10;
  transition: 0.5s;

  &--hidden{
    opacity: 0;
    visibility: hidden;
  }
}

.modal {
  width: 400px;
  background-color: white;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;

  &-title {
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 700;
  }

  &-body {
    padding: 25px 30px;
    font-size: 18px;
  }

  &-action {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
  }

  &-button {
    flex: 1;
    padding: 20px 30px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #ff395e;

      color:white;
    }
    &:last-child{
      &:hover{
      background-color: $green;
      }
    }
  }

  & > div {
    border-bottom: 1px solid #ebebeb;

    &:last-child {
      border-bottom: none;
    }
  }
  @media only screen and (max-width: 450px) {
    width:auto;
    margin: 14px;
  }
}
