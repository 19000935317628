.page-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  position: fixed;
  z-index: 3000;
  transition: 1s;

  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 1s;
    transition-delay: 1s;
    opacity: 1;
    position: relative;
    @media only screen and (max-width: 680px) {
      flex-direction: column;
    }
  }

  &-animation {
    &__icon {
      background-image: url("data:image/svg+xml, #{$logo-icon-green}");
      background-size: contain;
      background-repeat: no-repeat;
      width: 160px;
      height: 160px;
      background-size: 25%;
      background-position: center;
      position: absolute;
      z-index: 10;
    }
    &__text{
      background-image: url("/assets/images/logo_text_loading.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 190px;
      height: 70px;
      margin-top: -10px;
      background-position: center;
      z-index: 10;
    }

    &__circle {
      width: 160px;
      height: 160px;
      animation: spinLoader 1.4925373134328357s infinite linear;

      &__stroke {
        animation: strokePageLoader 1.4925373134328357s infinite linear;
      }
    }
  }

  &--hidden {
    transition: 1s;
    transition-delay: 1s;
    background-color: rgba(250, 250, 250, 0);
    .page-loading-wrapper{
      transition-delay: 0s;
      opacity: 0;
    }
  }
}

.reader-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  position: fixed;
  z-index: 2000;
  opacity: 1;
  transition: 0.5s;

  &__circle {
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    animation: spinLoader 0.85s infinite linear;
    &__stroke {
      animation: strokeReaderLoader 0.85s infinite linear;
    }
  }

  &--hidden {
    opacity: 0;
  }
}

@keyframes spinLoader {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(720deg);
  }
}
@keyframes strokePageLoader {
  0% {
    stroke-dasharray: 20,180;
  }

  50% {
    stroke-dasharray: 138,64;
  }

  100% {
    stroke-dasharray: 20,181;
  }
}
@keyframes strokeReaderLoader {
  0% {
    stroke-dasharray: 30,158;
  }

  50% {
    stroke-dasharray: 64,124;
  }

  100% {
    stroke-dasharray: 30,158;
  }
}
