$answer-padding: 35px 45px;
$answer-padding-medium: 25px 35px;

.main-exam {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .exam-content-wrapper {
    display: flex;
    width: 10000000px;
    height: 100vh;
  }

  .exam-items-container {
    display: flex;
    transition: margin 1.5s;

    &.is-resizing {
      transition: 0s;
    }
  }

  .exam-item {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s, opacity 1.5s;
    transition-delay: 0.25s;
    margin: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow-y: scroll;
    margin-top: 70px;
    height: calc(100vh - 70px);

    &--active {
      visibility: visible;
      opacity: 1;
    }

    &--message {
      .question-container {
        flex-direction: column;
        justify-content: center;
      }
    }

    &--question {
      .exam-explanation {
        display: none;
      }
    }

    &--result {
      .question-wrapper {
        .round-button {
          display: none;
        }
      }
    }
  }

  .exam-answers {
    background-color: white;
    padding: 0;
    list-style-type: none;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 0 0 auto;
    overflow: hidden;

    li {
      border-bottom: 2px solid #efefef;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.5s;
      flex: 1 0 auto;

      &:after {
        font-family: fontello;
        content: '\E80D';
        padding: $answer-padding;
        color: white;
      }

      a {
        width: 90%;
        padding: $answer-padding;
        display: block;
        box-sizing: border-box;
      }

      &:hover {
        color: $blue;
      }

      &.answer-active {
        color: white;
        background-color: $blue;
      }

      &.answer-correct {
        color: $green-exam;
        background-color: white;
        font-weight: 500;

        &:after {
          color: $green-exam;
        }
      }

      &.answer-wrong {
        color: white;
        background-color: $red;

        &:after {
          content: '✘';
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .question-wrapper {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::after {
      height: 20px;
      width: 10px;
      content: '.';
      display: block;
      opacity: 0;
    }
  }

  .question-container {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 30px 0;

    .flex-row {
      .exam-result-badge,
      .round-button {
        margin: 0 5px;
      }
    }

    h1 {
      font-size: 28px;
      line-height: 1;
      padding: 20px 50px 20px 0;
      margin: 0;
    }

    h2 {
      padding: 50px;
      font-size: 30px;
      text-align: center;
      line-height: 1;
      max-width: 600px;
    }

    .exam-result-badge {
      color: $green;
      font-size: 44px;
      font-weight: 600;
      width: 100px;
      height: 100px;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 100%;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);

      &--false {
        color: $red;
      }
    }
  }

  .exam-explanation {
    width: 300px;
    margin: auto auto auto 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    b,
    p {
      font-size: 18px;
    }

    .round-button {
      padding: 10px 30px;
      margin-top: 20px;
      font-size: 18px;
    }
    &::after {
      height: 20px;
      width: 10px;
      content: '.';
      display: block;
      opacity: 0;
    }
  }

  .round-button {
    padding: 20px 50px;
    background-color: #e3e3e3;
    text-align: center;
    font-weight: 500;
    border-radius: 40px;
    color: #ffffff;
    align-self: center;
    transition: 0.5s;
    display: inline-block;
    opacity: 1;

    &:hover {
      background-color: $blue;
    }

    &--cancel {
      &:hover {
        background-color: red;
      }
    }

    &--gray {
      background: none;
      border: 2px solid #cbcbcb;
      color: #cbcbcb;

      &:hover {
        background-color: #cbcbcb;
        color: white;
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .main-exam .exam-answers li {
    font-size: 16px;

    &:after {
      padding: $answer-padding-medium;
    }

    a {
      padding: $answer-padding-medium;
    }
  }

  .main-exam .question-container {
    & h1 {
      font-size: 25px;
    }

    & h2 {
      font-size: 25px;
    }
  }

  .main-exam .round-button {
    padding: 15px 25px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 800px) {
  .main-exam .question-wrapper {
    width: 100%;
    justify-content: flex-start;
  }
  .main-exam .question-container {
    width: 100vw;
    margin: 20px;
  }
  .main-exam .exam-item--result .question-container{
    flex-direction: column;
    .exam-explanation{
      width:100%;
      margin:auto;
      margin-top: 10px;
      display:block;
      flex:0 0;
    }
  }
}
