.menu-chapter-wrapper,
.menu-treatment-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.main-menu,
.treatments-menu {
  .menu-chapter-wrapper,
  .menu-message,
  .menu-section,
  .menu-treatment-wrapper {
    width: 1110px;
  }
  @media only screen and (max-width: 1380px) and (min-width: 681px) {
    .menu-chapter-wrapper,
    .menu-message,
    .menu-section,
    .menu-treatment-wrapper {
      width: 960px;
    }

    .menu-treatment {
      width: 300px;
      height: 350px;
    }

    .menu-chapter {
      width: 300px;
      height: 440px;
      margin: 10px;

      &__content {
        height: 395px;
      }

      &__bottom-bar {
        height: 45px;
      }

      &__title {
        font-size: 31px;
        line-height: 24px;
      }

      &__info {
        padding: 22px 22px 0;
      }

      &__duration {
        font-size: 16px;
      }

      &__description {
        padding: 0 20px 20px;
        font-size: 15px;
      }

      &__button {
        padding: 26px;
        font-size: 15px;
      }

      &__locked__lock {
        &:after {
          font-size: 23px;
        }
      }

      &__complete p {
        font-size: 18px;
      }

      &--wide {
        width: 100%;
        background-position: center;
      }
    }
  }
  @media only screen and (max-width: 1030px) {
    .menu-chapter-wrapper,
    .menu-message,
    .menu-section,
    .menu-treatment-wrapper {
      width: 640px;
    }
  }
  @media only screen and (max-width: 680px) {
    .menu-chapter-wrapper,
    .menu-message,
    .menu-section,
    .menu-treatment-wrapper {
      width: 350px;
    }
  }
  @media only screen and (max-width: 500px) {
    .menu-chapter-wrapper,
    .menu-message,
    .menu-section,
    .menu-treatment-wrapper {
      width: auto;
      max-width: 100%;
    }

    .menu-treatment {
      width: 100%;
      margin: 10px 20px;
    }

    .menu-section {
      min-width: 100%;
    }

    .menu-chapter,
    .menu-chapter {
      width: 100%;
      height: 550px;
      margin: 20px;
    }

    .menu-content-wrapper {
      padding: 140px 0 0;
    }

    .menu-chapter__content {
      height: 510px;
    }

    .menu-chapter {
      background-position: center;
      background-size: cover;

      &--wide {
        background-size: contain;
      }
    }

    .menu-message {
      padding: 0 30px;

      &__wrapper {
        flex-direction: column;
        padding: 20px;
        border-radius: 20px;
      }

      &__icon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
    }

    h4 {
      margin: 0 30px;
      font-size: 35px;
    }
  }
}

.menu-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 140px 0;
}

.menu-message {
  &__wrapper {
    background-color: $green;
    color: white;
    padding: 30px 60px;
    border-radius: 50px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--grey {
      background-color: $lightgrey;
      color: #707070;
    }

    &--red {
      background-color: $lightred;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  &__text {
    font-size: 17px;
  }
}

.menu-section {
  margin-bottom: 60px;
  padding-bottom: 80px;
  border-bottom: 2px solid #eaeaea;

  &:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }
}

.menu-chapter {
  width: 350px;
  height: 500px;
  background-color: white;
  margin: 10px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);
  background-image: url("/assets/images/footage/menu.jpg");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  &--wide {
    width: 100%;
    background-position: center;
    background-image: url("/assets/images/footage/decubitus_menu.png");
  }

  &--hover,
  &:hover {
    .menu-chapter {
      &__overlay {
        opacity: 1;
        visibility: visible;
      }

      &__content {
        background-color: rgba(0, 207,93, 0.75);
      }

      &__info {
        color: white;
      }
    }

    &.menu-chapter--state-locked {
      .menu-chapter__content {
        background-color: rgba(176, 176, 176, 0.85);
      }

      .menu-chapter__bottom-bar {
        background-color: white;
        color: $lightgrey;
      }
    }

    &.menu-chapter--state-progress {
      .menu-chapter__bottom-bar {
        background-color: white;

        &.menu-chapter__progress__container {
          background-color: #e2e2e2;
        }
      }
    }

    &.menu-chapter--state-complete {
      .menu-chapter__complete {
        background-color: white;
        color: $green;
      }
    }
  }

  &--state-locked {
    .menu-chapter__locked {
      display: flex;
      height: 100%;
    }

    .menu-chapter__bottom-bar {
      color: #eee;
    }
  }

  &--state-progress {
    .menu-chapter__progress {
      display: flex;
    }
  }

  &--state-complete {
    .menu-chapter__complete {
      display: flex;
    }
  }

  &--state-active {
    .menu-chapter__content {
      background-color: rgba(0, 207,93, 0.75);
    }

    .menu-chapter__overlay {
      opacity: 1;
    }

    .menu-chapter__info {
      color: white;
    }
  }

  &__content {
    height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__overlay {
    opacity: 0;
    visibility: hidden;
  }

  &__content,
  &__overlay {
    transition: opacity 0.5s, background-color 0.5s;
  }

  &__info {
    padding: 25px 25px 0;
  }

  &__title {
    font-size: 34px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__duration {
    font-size: 19px;
  }

  &__score {
    font-weight: 900;
  }

  &__bottom-bar {
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: opacity 0.5s, background-color 0.5s;
  }

  &__progress {
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    &__container {
      width: 30%;
      height: 10px;
      background-color: #ececec;
      border-radius: 10px;
      overflow: hidden;
      transition: 0.5s;
    }

    &__percentage {
      width: 0;
      min-width: 10px;
      height: 100%;
      border-radius: 10px;
      background-color: $green;
    }
  }

  &__locked {
    display: flex;
    justify-content: center;
    align-items: center;

    &__lock {
      &:after {
        font-family: fontello;
        content: '\E80B';
        opacity: 1;
        font-size: 25px;
      }
    }
  }

  &__complete {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
    background-color: rgba(0, 207, 93, 1);
    transition: opacity 0.5s, background-color 0.5s;

    & p {
      font-size: 19px;
    }
  }

  &__locked {
    display: none;
  }

  &__complete {
    display: none;
  }

  &__button {
    background-color: white;
    padding: 30px;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;

    &:after {
      font-family: fontello;
    }

    &--complete {
      color: $green;

      &:after {
        content: '\E80D';
      }

      &:hover {
        color: $green;
        background-color: $light-grey-select;

        &:after {
          content: '\E80C';
        }
      }
    }

    &--score {
      color: $green;

      &:hover {
        color: $green;
        background-color: $light-grey-select;

        .menu-chapter__score {
          display: none;
        }

        &:after {
          content: '\E80C';
        }
      }
    }

    &--fail {
      color: #ff0051;

      &:hover {
        background-color: $light-grey-select;

        .menu-chapter__score {
          display: none;
        }

        &:after {
          content: '\E80C';
        }
      }
    }

    &--ready {
      &:after {
        content: '\E80C';
      }

      &:hover {
        color: $green;
        background-color: $light-grey-select;
      }
    }

    &--locked {
      color: #c1c1c1;
      cursor: default;

      &:after {
        content: '\E80B';
      }
    }
  }

  &__description {
    color: white;
    padding: 0 20px 20px;
    font-size: 16px;
    font-weight: 500;
  }
}

.menu-treatment {
  width: 350px;
  height: 400px;
  background-color: white;
  margin: 10px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    .menu-treatment__title-wrapper {
      background-color: $blue;
      color: white;
    }
  }

  &__image-wrapper {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    max-width: 90%;
    max-height: 90%;
  }

  &__title-wrapper {
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    font-weight: 500;
    transition: opacity 0.5s, background-color 0.5s;
  }
}

.menu-subscription-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;

  &__head {
    margin-top: 70px;
    font-weight: 700;
    font-size: 30px;
    color: #9c9c9c;
    text-align: center;
  }

  &__body {
    color: #9c9c9c;
    text-align: center;
    font-size: 18px;
  }
}
