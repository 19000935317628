.settings-main {
  display: flex;
  justify-content: center;
  width:100%;

  h1 {
    font-size: 55px;
    margin: 0;
    padding: 20px;
    @media screen and (max-width: 680px) {
      font-size: 30px;
      line-height: 1;
    }
  }

  h2 {
    font-size: 30px;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 10px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 680px) {
    p{
      font-size: 16px;
    }
  }
}

.settings-wrapper {
  padding-top: 140px;
  max-width: 600px;
  @media screen and (max-width: 680px) {
    margin: 30px;
    max-width: 600px;
    width:100%;
  }
}

.diploma-item {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 0;

  p {
    font-size: 15px;
    margin-top: 10px;
  }

  h2 {
    font-size: 30px;
    line-height: 1;
  }

  &__section {
    padding: 5px 20px;
  }

  &__balloon {
    margin: 10px 20px;
    background-color: #eaeaea;
    border-radius: 7px;
    padding: 15px;
    box-sizing: border-box;
  }

  &__text {
    p {
      margin-top: 0;
    }
  }

  &__progress {
    width: 100%;
    height: 15px;
    background-color: #eaeaea;
    border-radius: 5px;
    margin: 10px 0;

    &-bar {
      width: 33%;
      background-color: $blue;
      height: 100%;
      border-radius: 5px;

      &--success {
        background-color: $green;
      }
    }
  }

  button {
    width: 100%;
    border: none;
    font-size: 22px;
    padding: 10px 0;
    text-indent: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: content-box;
    background-color: $green;
    color: white;
    font-weight: bold;
    text-align: left;
    transition: 0.2s;

    &:hover {
      background-color: #3ebe69;
    }

    &:disabled {
      background-color: $light-grey-select;
      color: $lightgrey;
      cursor: default;
    }
  }
}

.personalia-item {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  &--success {
    box-shadow: none;
    background-color: #c7ffda;
    color: #00c643;
    padding: 20px;
    box-sizing: border-box;
  }

  &--error {
    box-shadow: none;
    background-color: #ffc7c7;
    color: #ef1141;
    padding: 20px;
    box-sizing: border-box;
  }
}

.settings-item {
  width: 600px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: 0.15s;

  p,
  span {
    padding: 20px;
    font-size: 19px;
  }

  &:after {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url("/assets/images/next.svg");
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.25;
  }

  &--back {
    flex-direction: row-reverse;
    width: 33%;

    &:after {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background-color: $green;
    color: white;
  }
  @media screen and (max-width: 620px) {
    width: 95vw;
  }
}

.settings-page {
  width: 600px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  .settings-text-box {
    padding: 20px 40px 40px;

    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }

  h1 {
    padding: 40px;
  }

  h1 {
    padding-bottom: 0;
  }
}

.settings-form {
  padding-top: 15px;

  p {
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
  }

  &-divider {
    display: block;
    background-color: #e1f1ff;
    color: #0089ff;
    padding: 26px 20px;
    font-size: 15px;
  }

  &__item {
    padding: 15px 20px;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 5px;

    &--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 20%;
        padding: 20px;
      }
    }

    &--error {
      color: red;
    }

    &--locked {
      background-color: #f6f6f6;
      border-radius: 0;
      opacity: 0.5;

      input {
        background-color: #f6f6f6;
      }
    }
    a{
      color:$blue;
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  select {
    width: 100%;
    border: none;
    font-size: 18px;
    height: 35px;
    background-color: #f6f6f6;
    color: #898989;
    margin-top: 5px;
    padding: 0;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"] {
    color: #898989;
    //background-color: #f9f9f9;
    font-size: 18px;
    line-height: 28px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    width: 100%;
  }

  input[type="submit"] {
    width: 100%;
    border: none;
    font-size: 22px;
    padding: 15px 0;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: content-box;
    background-color: $blue;
    color: white;
    transition: 0.2s;

    &:hover {
      background-color: $navy;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    padding: 10px 5px;

    input[type="checkbox"] {
      display: none;
    }

    input:checked+label {
      background-color: $blue;
      border-radius: 3px 3px 3px 3px;

      &:before {
        opacity: 1;
      }
    }

    input:disabled+label {
      cursor: default;
    }

    p {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      margin-left: 10px;
      margin-top: 0;
    }

    label {
      width: 20px!important;
      height: 20px !important;
      border: 1px #3a3a3a solid;
      display: inline-block;
      font-size: 40pt;
      cursor: pointer;
      position: relative;
      background-color: #f9f9f9;
      border-width: 1px;
      border-style: solid;
      border-color: #e5e5e5;
      margin-right: 5px;
      border-radius: 3px 3px 3px 3px;

      &:before {
        position: absolute;
        top: 4.5px;
        left: 2.5px;
        height: 11px;
        width: 17px;
        background-image: url("/assets/images/checkbox.png");
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        opacity: 0;
      }
    }
  }
}

.settings-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/login-bg.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 60px;
  background-attachment: fixed;
}
