.main-casus {
  .menu-section {
    max-width: 740px;
  }
  @media only screen and (max-width: 830px) {
    .menu-section {
      max-width: 490px;
    }
  }
  @media only screen and (max-width: 590px) {
    .menu-section {
      max-width: 245px;
    }
  }
  @media only screen and (max-width: 640px) {
    .casus-badge-container--false, .casus-badge-container--true{
      display: block;
    }
    .casus-result-badge{
      width: auto;
      height:auto;
      background-color: transparent;
      box-shadow: none;
      margin:0;
      padding:0;
      text-align: center;
    }
  }

  .single-image-container {
    max-width: 800px;
  }

  .casus-thumbnails {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }

  .menu-section {
    margin-bottom: 40px;
    padding: 0 40px 40px;
    border-bottom: none;


    &:last-child {
      margin-bottom: 0;
    }
  }

  .casus-divider {
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.09);
  }

  h4 {
    margin: 10px;
    font-size: 29px;
    font-weight: 300;
  }

  h3 {
    color: #0289ff;
    font-size: 44px;
    text-align: center;
    padding: 20px;
    font-weight: 300;
    font-size: 31px;
  }

  .menu-treatment {
    width: 235px;
    height: 270px;
    background-color: white;
    margin: 5px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    transition: 0.2s;

    &--active {
      border: 4px solid $blue;

      .menu-treatment__title-wrapper {
        background-color: $blue;
        color: white;
        border-radius: 0 0;
      }
    }

    &--correct {
      border: 4px solid $green;

      .menu-treatment__title-wrapper {
        background-color: $green;
        color: white;
        border-radius: 0 0;
      }

      &:hover {
        .menu-treatment__title-wrapper {
          background-color: $green;
          color: white;
          border-radius: 0 0;
        }
      }
    }

    &--false {
      border: 4px solid $red;

      .menu-treatment__title-wrapper {
        background-color: $red;
        color: white;
        border-radius: 0 0;
      }

      &:hover {
        .menu-treatment__title-wrapper {
          background-color: $red;
          color: white;
          border-radius: 0 0;
        }
      }
    }

    &__image-wrapper {
      width: 200px;
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__image {
      width: auto;
      height: 160px;
    }

    &__title-wrapper {
      height: 40px;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      font-weight: 500;
      transition: 0.2s;
      border-radius: 0 0 5px 5px;

      p {
        font-size: 16px;
      }
    }
  }

  .casus-send {
    text-align: center;

    .round-button {
      width: 100%;
      padding: 20px 0;
    }
  }
  .menu-message__wrapper {
    background-color: #f2f2f2;
    color: #707070;
    padding: 30px;
    border-radius: 10px;
    margin-bottom:0;
    margin-top:40px;
    .menu-message__text {
    font-size: 15px;
    }
    .casus-answer-number {
    position:inherit;
    align-self:auto;
    padding-right: 20px;
    padding-top: 0;
}
}
}

.casus-anamnese {
  width: 600px;

  &-item {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    &--deactivated {
      // background-color: #fafafa;
      // box-shadow: 0 0 0 black;
      // border: 1px solid #e4e4e4;
      display: none;

      .casus-anamnese-item__head {
        border-bottom: none;
        opacity: 0.4;
      }

      .casus-anamnese-item__text {
        display: none;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #e4e4e4;
    }

    &__label {
      font-weight: 600;
      margin-left: 10px;
    }

    &__text {
      padding: 20px;
      margin: 0;
      padding-inline-start: 50px;
    }

    &__icon {
      width: 35px;
      height: 35px;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.5;

      &--wondvocht {
        background-image: url("/assets/images/wondvocht.svg");
      }

      &--patient {
        background-image: url("/assets/images/patient.svg");
      }

      &--wond {
        background-image: url("/assets/images/wound.svg");
      }

      &--infectie {
        background-image: url("/assets/images/infection.svg");
      }

      &--weefsel {
        background-image: url("/assets/images/weefsel.svg");
      }

      &--wondranden {
        background-image: url("/assets/images/wondranden.svg");
      }
    }
  }
  @media only screen and (max-width: 680px) {
    width: 100%;

    &-item {
      margin: 10px 40px;
    }
  }
}

.casus-questions {

  .menu-section {
    &:first-child {
    margin-top:40px;
}
}
}


.casus-answers {

  .menu-section {
    &:nth-child(-n + 2) {
    margin-top:40px;
}
}
.menu-treatment-wrapper{
  justify-content: center;
}
  .menu-treatment {
    cursor: default;

    &:hover {
      .menu-treatment__title-wrapper {
        background-color: inherit;
        color: inherit;
      }
    }

    &--correct {
      border: 4px solid $green;

      .menu-treatment__title-wrapper {
        background-color: $green;
        color: white;
        border-radius: 0 0;
      }

      .casus-answer-number__text {
        background-color: $green;
        color: white;
        box-shadow: none;
      }

      &:hover {
        .menu-treatment__title-wrapper {
          background-color: $green;
          color: white;
          border-radius: 0 0;
        }
      }
    }

    &--false {
      border: 4px solid $red;

      .menu-treatment__title-wrapper {
        background-color: $red;
        color: white;
        border-radius: 0 0;
      }

      &:hover {
        .menu-treatment__title-wrapper {
          background-color: $red;
          color: white;
          border-radius: 0 0;
        }
      }
    }
  }

  .casus-toelichting {
    max-width: 700px;
    margin: 10px;
  }

  &--no-doubles{
    .menu-message__wrapper{
      display: none;
    }
  }

  &--no-numbers{
    .casus-answer-number{
      display: none;
    }
    .menu-message__wrapper{
      display: none;
    }
  }
}

.casus-result-badge {
  color: $green;
  font-size: 44px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);
  flex-shrink: 0;

  &--false {
    color: $red;
  }

  &--text {
    width: auto;
    border-radius: 100px;
    font-size: 30px;
    padding: 0 50px;
    margin-left: -20px;
  }
}

.casus-answer-number {
  position: absolute;
  align-self: flex-end;
  padding-right: 10px;
  padding-top: 10px;

  &__text {
    color: #a4a4a4;
    background-color: #fafafa;
    padding: 4px 11px;
    border-radius: 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 700;
    box-shadow: 0 1px 2px #00000057;
    font-size: 15px;
  }
  &:before{
      content:'';
      background-image: url("/assets/images/shuffle.svg");
      background-position: center;
      background-size: 50%;
      width:10px;
      height:10px;

  }
}
