.img-wrapper {
  max-width: 1000px;
  margin: 60px 0;
}

.illustration {
  width: 100%;
  flex-shrink: 0;

  &--png-margin {
    margin-left: -8.5%;
  }
}

.reader-content-wrapper .single-image-container {
  width: 1000px;
  flex-shrink: 0;
  position: relative;
  @media only screen and (max-width: 1380px) {
    width: 800px;
  }
  @media only screen and (max-width: 850px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}

.single-image {
  width: 100%;
  box-shadow: 0 13px 7px -9px rgba(0, 0, 0, 0.39);
}

.image-active {
  opacity: 1;
}

.single-image-caption-text {
  text-align: center;
  font-size: 18px;
  padding: 20px 200px;
  font-style: italic;
  opacity: 0.5;
  @media only screen and (max-width: 1380px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 680px) {
    padding: 20px 0;
  }
}

.single-image-caption-text:before {
  font-family: fontello;
  content: "\E80E";
  font-style: normal;
  margin-right: 10px;
}
