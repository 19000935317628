.reader-footer {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1000;
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  transform: translateY(-100%);
  position: absolute;

  &-button {
    height: 70px;
    background-color: white;
    display: flex;
    align-items: center;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px 5px 0 0;
    transition: 0.25s;
    margin: 0 5px;

    &:hover {
      .reader-footer-button__text:after {
        opacity: 1;
        width: 15px;
        margin-left: 10px;
      }
    }

    &--hidden {
      margin-bottom: -70px;
    }

    &__text {
      padding: 0 20px;
      text-decoration: none;
      font-weight: 500;
      color: #0588ff;
      height: 100%;
      display: flex;
      align-items: center;
      transition: 0.25s;

      &:after {
        opacity: 0;
        font-family: fontello;
        content: '\E80C';
        font-size: 17px;
        margin-left: 5px;
        width: 0;
        height: 15px;
        transition: 0.25s;
        line-height: 1;
      }
    }
  }
  @media screen and (max-width: 680px) {
    font-size: 18px;
    flex-direction: column;
    &-button{
      border-radius: 5px;
      margin-bottom:10px;
      &__text{
        width: 100%;
        justify-content: space-between;
        &:after{
          opacity: 1;
          width: 15px;
          margin-left: 10px;
        }
      }
    }
  }
}
