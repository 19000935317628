.gallery-image-caption-text:before {
  content: "ⓘ";
  font-style: normal;
  margin-right: 10px;
}

.image-gallery {
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.image-gallery-wrapper {
  display: flex;
  width: 100000px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.gallery-image-container {
  margin: 0 10px;
  display: table;
  width: 1px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    .gallery-image {
      opacity: 0.75;
    }

    .gallery-image-caption {
      opacity: 0.2;
    }
    .gallery-image-watermark__arrows{
      opacity: 1;
    }
    @media only screen and (max-width: 680px) {
      .gallery-image {
        opacity: 1;
      }
      .gallery-image-caption {
        opacity: 1;
      }
      .gallery-image-watermark__arrows{
        opacity: 0;
      }
    }
  }

  &:first-child {
    margin-left: 40px;
  }

  &:last-child {
    margin-right: 40px;
  }
  // previous and next button-container

  .gallery-image-watermark {
    &__arrows {
      .watermark-arrow{
        &--left{
          opacity: 1;
        }
        &--right{
          opacity: 0;
        }
      }
    }
  }

  &--active {
    .watermark-elem {
      opacity: 1;
    }

    .gallery-image-watermark {
      &__arrows {
        .watermark-arrow{
          &--left{
          opacity: 0;
          }
          &--right{
          opacity: 0;
          }
        }
      }
    }

    & ~ div {
      .gallery-image-watermark {
        &__arrows {
          .watermark-arrow{
            &--left{
            opacity: 0;
            }
            &--right{
            opacity: 1;
            }
          }
        }
      }
    }
  }

  &--active {
    .gallery-image {
      opacity: 1;
    }

    .gallery-image-caption {
      opacity: 1;
    }

    .gallery-image-watermark {
      opacity: 1;
    }
  }

  &--active,
  &--visible {
    transform: scale(1);

    &:hover {
      .gallery-image {
        opacity: 1;
      }

      .gallery-image-caption {
        opacity: 1;
      }
      .gallery-image-watermark__arrows{
        opacity: 0;
      }
    }
  }
}

.gallery-image {
  height: 30vw;
  box-shadow: 0 13px 7px -9px rgba(0, 0, 0, 0.39);
  transition: 0.5s;
  border: 0 solid $blue;
  box-sizing: border-box;
  opacity: 0.5;
  @media screen and (max-width: 1300px) {
    height: 35vw;
  }
  @media screen and (max-width: 1100px) {
    height: 40vw;
  }
  @media screen and (max-width: 850px) {
    height: 50vw;
  }
}

.watermark-elem {
  opacity: 0;
  transition: 1s;
  z-index: 100;
  @media only screen and (max-width: 680px) {
    opacity: 1;
  }
}

.single-image-container{
  .watermark-elem {
    opacity: 1;
    transition: 1s;
  }
}
.gallery-image-watermark {
  height: 50px;
  margin-top: -50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 30px 20px;
  box-sizing: border-box;

  &__logo {
    background-image: url("data:image/svg+xml, #{$logo-text-watermark}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 0;
    height: 28px;
    width: 100px;
  }

  &__copyright {
    color: white;
    font-size: 19px;
  }

  &__arrows {
        position: absolute;
        width: 100%;
        height: 30vw;
        top: 0;
        color: white;
        font-size: 4vw;
        font-weight: 900;
        opacity: 0;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1300px) {
          height: 35vw;

        }
        @media screen and (max-width: 1100px) {
          height: 40vw;

        }
        @media screen and (max-width: 850px) {
          height: 50vw;
        }

    .watermark-arrow{
      &--left{
      opacity: 0;
      }
      &--right{
      opacity: 1;
      }
    }
  }
}

.watermark-arrow{
  width:50px;
  height:50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: 1s;
  position: absolute;
  &--left{
  background-image: url("/assets/images/gallery_arrow_left.svg");
  }
  &--right{
    position: absolute;
  background-image: url("/assets/images/gallery_arrow_right.svg");
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   margin-left:-25px;
   margin-top:-25px;
}
}

.gallery-image-wrap {
  position: relative;
}

.gallery-image-caption {
  max-width: 60vw;
  opacity: 0.2;
  transition: 0.5s;
}

.gallery-image-caption-text {
  font-size: 18px;
  padding: 20px 20px 20px 0;
  font-style: italic;
  width: 100%;
  @media only screen and (max-width: 1380px) {
    font-size: 16px;
  }
}
// @media only screen and (max-width: 680px) {
//   .reader-content-wrapper .reader-treatment {
//     margin: 0 30px;
//   }
//
//   .reader-treatment__image {
//     width: 80%;
//     height: auto;
//
//     &-wrapper {
//       display: flex;
//       justify-content: center;
//     }
//   }
//   .gallery-image {
//     width: 88vw;
//     height:auto;
//   }
//   .image-gallery-wrapper {
//     width: 100vw;
//     overflow: scroll;
//     scroll-snap-type: x mandatory;
//   }
//
//   .gallery-image-container {
//     scroll-snap-align: center;
//     scroll-snap-stop: always;
//     margin: 0 5px;
//     opacity: 1;
//     &:first-child{
//       margin-left:10px;
//     }
//     &:last-child{
//       padding-right:10px;
//     }
//   }
// }
@media only screen and (max-width: 680px) {
  .image-gallery-wrapper {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .gallery-image-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    margin: auto auto 20px;

    &:first-child {
      margin-left: auto;
    }
  }

  .gallery-image-caption {
    max-width: 100%;
    opacity: 1;

    &-text {
      text-align: center;
      font-size: 16px;
      font-style: italic;
      opacity: 0.5;
    }
  }

  .gallery-image {
    height: auto;
    width: 100%;
    opacity: 1;
  }
}
