$nav-height-large: 65px;
$nav-height-small: 35px;

.nav-bar {
  width: 100%;
  background-color: white;
  height: 70px;
  position: fixed;
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.10);
  z-index: 2000;
  transition: 0.5s;

  &-progressbar {
    width: 100%;
    height: 5px;

    &__percentage {
      width: 34%;
      height: 100%;
      background-color: $blue;
      transition: 1s;
    }
  }

  &--hidden {
    margin-top: -70px;
  }

  &-menu {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 2.5px;
  }

  &-business,
  &-casus,
  &-exam,
  &-reader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-section {
    height: $nav-height-large;
    padding-left: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;

    &--clock {
      padding-right: 30px;
      border-right: 1px solid hsl(0, 0%, 90%);
      color: #bbbbbb;
    }

    &--light {
      color: #bbbbbb;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-text {
      margin: 15px;
      font-weight: 500;
      color: $lightgrey;

      &--active {
        color: $green;
        border-bottom: 2px solid $green;
      }

      &:hover {
        color: $green;
      }
    }
  }

  .nav-icon {
    width: $nav-height-large;
    height: $nav-height-large;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 0.25s;

    &--border {
      border-right: 1px solid hsl(0, 0%, 90%);

      &:hover {
        background-color: #f6f6f6;
      }
    }

    &--qcare {
      background-image: url("data:image/svg+xml, #{$logo-dashboard}");
      background-size: 100%;
      background-position-y: 60%;
      cursor: default;
      width: 160px;
      margin-left: 20px;
      transition: 0s;
    }

    &--settings {
      background-image: url("data:image/svg+xml, #{$settings}");
      background-size: 55%;

      &--active,
      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$settingsHover}");
      }
    }

    &--account {
      background-image: url("data:image/svg+xml, #{$account}");
      background-size: 55%;

      &--active,
      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$accountHover}");
      }
    }

    &--next {
      background-image: url("data:image/svg+xml, #{$next}");
      background-size: 23%;

      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$nextHover}");
      }

      &--disabled {
        opacity: 0.2;
        border-right: 1px solid hsl(0, 0%, 60%);
        cursor: default;

        &:focus,
        &:hover {
          background-image: url("data:image/svg+xml, #{$next}");
          background-color: white;
        }
      }
    }

    &--prev {
      background-image: url("data:image/svg+xml, #{$prev}");
      background-size: 23%;

      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$prevHover}");
      }

      &--disabled {
        opacity: 0.2;
        border-right: 1px solid hsl(0, 0%, 60%);
        cursor: default;

        &:focus,
        &:hover {
          background-image: url("data:image/svg+xml, #{$prev}");
          background-color: white;
        }
      }
    }

    &--close {
      background-image: url("data:image/svg+xml, #{$close}");
      background-size: 50%;

      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$closeHover}");
      }
    }

    &--admin{
      background-image: url("data:image/svg+xml, #{$admin}");
      background-size: 40%;

      &:focus,
      &:hover {
        background-image: url("data:image/svg+xml, #{$adminHover}");
      }
    }

    &--module {
      display: flex;
      align-items: center;
      width: auto;
      font-size: 17px;
      padding-right: 30px;
      color: black;

      &__icon {
        width: $nav-height-large;
        height: $nav-height-large;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
@media only screen and (max-width: 600px){
 .nav-bar-menu {
      .nav-icon--qcare {
        background-image: url("data:image/svg+xml, #{$logo-icon}");
        background-size: 55%;
        background-position-y: 55%;
        cursor: default;
        width: 65px;
        margin-left: 0px;
      }
    }
}
@media only screen and (max-width: 450px) {
  .nav-bar-menu {
    font-size: 5vw;

    .button-container {
      & .nav-text {
        margin-left: 1vw;

        &:first-child {
          margin-left: 20px;
        }
      }

      & .nav-icon {
        width: 45px;
        background-size: 65%;

        &:last-child {
          margin-right: 10px;
        }
      }
    }

    .nav-icon--qcare {
      display: none;
    }
  }

  .nav-bar-reader {
    .nav-section {
      line-height: 15px;
      font-size: 17px;
    }
  }

  .nav-bar-business {
    .nav-icon--qcare {
      display: none;
    }

    .nav-section {
      line-height: 15px;
      font-size: 17px;
    }
  }

  .nav-bar-exam {
    .nav-section {
      line-height: 15px;
      font-size: 17px;
      padding: 0;
      display: flex;
      justify-content: center;
      flex: auto;
    }

    .button-container {
      width: 100%;
    }
  }

  .nav-bar-casus {
    .nav-section {
      line-height: 15px;
      font-size: 17px;
    }
  }
}
