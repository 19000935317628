.reader-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 140px;
  min-height: 100vh;
  box-sizing: border-box;

  .reader-item {
    margin: 40px 0;
    transition: 1.5s;

    &--inactive {
      opacity: 0;
      margin-top: 150px;
    }

    &:last-child {
      padding-bottom: 140px;
    }

    &.reader-treatment {
      padding-bottom: 0;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 680px) {
      margin: 20px 0;

      &:last-child {
        padding-bottom: 160px;
      }
      &.reader-treatment {
        width: calc(100% - 40px);
      }
      & .reader-treatment__wrapper {
        flex-direction: column;
      }
      & .reader-treatment__image-wrapper, .reader-treatment__info-wrapper{
        width: 100%;
      }
      & .reader-treatment__image-wrapper{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }

  .reader-keypoints {
    margin-top: auto;
    margin-bottom: 0;
  }

  &--modal {
    position: fixed;
  }
  // @media screen and (max-width: 680px) {
  //   .reader-item {
  //     margin: 20px 0;
  //   }
  // }
}

.reader-text {
  max-width: 600px;
  hyphens: none;

  &-wrapper {
    padding: 0 40px;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    font-size: 19px;
  }
  @media only screen and (max-width: 1380px) {
    h1 {
      font-size: 55px;
      margin-bottom: 5px;
    }

    h2,
    h3 {
      font-size: 35px;
      margin-bottom: 5px;
    }

    p,
    ul {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 680px) {
    width: 100%;

    h1 {
      font-size: 11vw;
      line-height: 1;
    }

    h2,
    h3 {
      font-size: 7vw;
      line-height: 1;
    }
  }
}
