.sidebar-wrapper {
  background-color: red;
}
.sidebar {
  width: 300px;
  margin-left: -300px;
  transition: 0.5s;
}
.sidebar-content {
  width: 300px;
  background-color: #292929;
  height: 100%;
  position: fixed;
}
.sidebar-content {
  width: 300px;
  background-color: #292929;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
